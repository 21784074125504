import React from 'react'
import { Modal, Button } from 'react-bootstrap'

const SuccessModal = ({
  show,
  handleClose,
  message = 'Votre signalement a bien été pris en compte ! Merci',
}) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message}{' '}
        {/* Afficher le message passé en props ou le message par défaut */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SuccessModal
