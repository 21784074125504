import { useState, useCallback, useEffect, useContext } from 'react'
import db from '../../../db/db'
import { UserContext } from '../../../context/userContext'
import { useFormWizardState } from '../../form/context/FormWizardContext'
import defaultPhoto from '../../../assets/images/defaultPhoto.jpg'

const useReportingsView = () => {
  const { currentUser } = useContext(UserContext)
  const { dispatch } = useFormWizardState()

  const [observations, setObservations] = useState([])
  const [activeKey, setActiveKey] = useState(null)
  const [showAddModal, setShowAddModal] = useState(false)
  const [establishmentName, setEstablishmentName] = useState('')
  const [loaded, setLoaded] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  // Fetch observations from IndexedDB
  const fetchObservationsFromIndexedDB = useCallback(async () => {
    if (currentUser?.uid) {
      try {
        const userObservations = await db.observations
          .where('userID')
          .equals(currentUser.uid)
          .toArray()

        userObservations.sort(
          (a, b) =>
            new Date(`${b.date}T${b.time}`) - new Date(`${a.date}T${a.time}`),
        )

        const enrichedObservations = await Promise.all(
          userObservations.map(async (observation) => {
            const establishment = await db.establishments.get(
              observation.establishmentRef,
            )

            // Ajouter la logique de la photo par défaut ici
            const photoURLs =
              observation.photoURLs && observation.photoURLs.length > 0
                ? observation.photoURLs
                : [defaultPhoto]

            return {
              ...observation,
              establishment: establishment || {},
              photoURLs: photoURLs, // Ajout des URLs de photo ici
            }
          }),
        )

        setObservations(enrichedObservations)
      } catch (error) {
        console.error('Failed to fetch observations from IndexedDB:', error)
      }
    }
  }, [currentUser])

  useEffect(() => {
    fetchObservationsFromIndexedDB()
  }, [currentUser, fetchObservationsFromIndexedDB])

  // Handle image loading
  const handleImageLoaded = (urlIndex) => {
    setLoaded((prevState) => ({ ...prevState, [urlIndex]: true }))
  }

  const isImageLoaded = (urlIndex) => {
    return loaded[urlIndex]
  }

  // Handle opening the add modal
  const handleOpenAddModal = (establishmentId) => {
    setShowAddModal(true)

    db.establishments.get(establishmentId).then((est) => {
      if (est) {
        setEstablishmentName(est.establishmentName)
        dispatch({
          type: 'UPDATE_COMPANY_NAME_MODAL',
          payload: {
            companyName: est.establishmentName,
            normalizedCompanyName: est.normalizedEstablishmentName,
          },
        })
        dispatch({
          type: 'UPDATE_COMPANY_ADDRESS',
          payload: est.address,
        })
        dispatch({
          type: 'SET_COMPANY_COORDINATES',
          payload: [est.coordinates.latitude, est.coordinates.longitude],
        })

        dispatch({
          type: 'SET_ESTABLISHMENT_EXISTS',
          payload: true,
        })

        dispatch({
          type: 'SET_CURRENT_ESTABLISHMENT_ID',
          payload: establishmentId,
        })
      } else {
        setEstablishmentName('Inconnu')
        dispatch({ type: 'RESET_COMPANY_ADDRESS' })
        dispatch({ type: 'RESET_COMPANY_NAME_MODAL' })
        dispatch({
          type: 'SET_ESTABLISHMENT_EXISTS',
          payload: false,
        })
        dispatch({ type: 'SET_CURRENT_ESTABLISHMENT_ID', payload: null })
      }
    })
  }

  // Handle closing the add modal
  const handleCloseAddModal = () => {
    setShowAddModal(false)
  }

  // Group and sort observations by establishment
  const observationsByEstablishment = observations.reduce((acc, obs) => {
    if (obs.establishment) {
      const key = obs.establishmentRef
      if (!acc[key]) {
        acc[key] = {
          name: obs.establishment.establishmentName,
          address: obs.establishment.address,
          observations: [],
        }
      }
      acc[key].observations.push({
        id: obs.id,
        date: obs.date,
        time: obs.time,
        photoURLs: obs.photoURLs,
        additionalNotes: obs.additionalNotes,
      })
    } else {
      console.error('Establishment details are missing for observation', obs)
    }
    return acc
  }, {})

  for (const key in observationsByEstablishment) {
    if (observationsByEstablishment[key].observations) {
      observationsByEstablishment[key].observations.sort(
        (a, b) =>
          new Date(`${b.date}T${b.time}`) - new Date(`${a.date}T${a.time}`),
      )
    }
  }

  return {
    observationsByEstablishment,
    activeKey,
    setActiveKey,
    handleImageLoaded,
    isImageLoaded,
    showAddModal,
    setShowAddModal,
    establishmentName,
    handleOpenAddModal,
    handleCloseAddModal,
    isLoading,
    setIsLoading,
    fetchObservationsFromIndexedDB,
  }
}

export default useReportingsView
