import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap'
import { motion } from 'framer-motion'
import AddObservationButton from '../AddObservationButton'
import AddObservationModal from '../AddObservationModal'
import SuccessModal from '../../form/modals/SuccessModal'
import { useModal } from '../../form/context/ModalContext'
import CustomToggle from './CustomToggle'
import ObservationDetail from './ObservationDetail'
import useReportingsView from './useReportingsView'
import useHandleSubmitClick from '../../form/hooks/useHandleSubmitClick'
import './ReportingsView.module.css'

const ReportingsView = () => {
  const {
    observationsByEstablishment,
    activeKey,
    setActiveKey,
    handleImageLoaded,
    isImageLoaded,
    showAddModal,
    setShowAddModal,
    establishmentName,
    handleOpenAddModal,
    handleCloseAddModal,
    isLoading,
    setIsLoading,
    fetchObservationsFromIndexedDB,
  } = useReportingsView()

  const { handleSubmitClick, handleDeleteClick } = useHandleSubmitClick(
    setIsLoading,
    setShowAddModal,
    fetchObservationsFromIndexedDB,
  )

  const {
    handleAddObservationClick,
    handleDeleteObservationClick,
    modalConfig,
    setModalConfig, // Ajout de setModalConfig ici
  } = useModal()

  return (
    <div className="reporting-view text-light">
      {Object.keys(observationsByEstablishment).length === 0 ? (
        <div className="text-center mt-5">
          <h4>Vous n'avez encore aucun signalement.</h4>
        </div>
      ) : (
        <Accordion activeKey={activeKey}>
          {Object.entries(observationsByEstablishment).map(
            ([key, { name, address, observations }], index) => (
              <Card key={key} className="mb-3 bg-dark">
                <motion.div
                  initial={{ x: -60 }}
                  animate={{ x: 0 }}
                  transition={{ delay: 0.1 + index * 0.04, duration: 0.15 }}
                  className="card-header bg-dark text-light rounded shadow"
                >
                  <CustomToggle
                    eventKey={`${index}`}
                    activeKey={activeKey}
                    setActiveKey={setActiveKey}
                  >
                    <h3>{name}</h3>
                    <div>{address.split(',')[0]}</div>
                  </CustomToggle>
                </motion.div>
                <Accordion.Collapse eventKey={`${index}`}>
                  <Card.Body className="bg-dark text-light rounded">
                    {observations.map((obs, obsIndex) => (
                      <div key={obsIndex} className="mb-3">
                        <ObservationDetail
                          observation={obs}
                          isImageLoaded={isImageLoaded}
                          handleImageLoaded={handleImageLoaded}
                        />
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() =>
                            handleDeleteObservationClick(
                              handleDeleteClick,
                              obs.id,
                              key,
                            )
                          }
                        >
                          Supprimer
                        </Button>
                      </div>
                    ))}
                    <div className="text-center">
                      <AddObservationButton
                        onClick={() => handleOpenAddModal(key)}
                      />
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ),
          )}
        </Accordion>
      )}
      <AddObservationModal
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        show={showAddModal}
        onHide={handleCloseAddModal}
        title={`Ajouter à ${establishmentName}`}
        handleAddObservation={() =>
          handleAddObservationClick(handleSubmitClick)
        }
      />

      {/* Utilisation de SuccessModal à la place de la modale manuelle */}
      <SuccessModal
        show={modalConfig.isVisible}
        handleClose={() => setModalConfig({ isVisible: false })}
        title={modalConfig.title}
        message={modalConfig.body}
        buttons={modalConfig.buttons}
      />
    </div>
  )
}

export default ReportingsView
