import React, { createContext, useState, useContext } from 'react'

const ModalContext = createContext()

export const ModalProvider = ({ children }) => {
  const [modalConfig, setModalConfig] = useState({
    isVisible: false,
    title: '',
    body: '',
    buttons: [],
  })

  const handleAddObservationClick = (handleSubmitClick) => {
    handleSubmitClick()
    setModalConfig({
      isVisible: true,
      title: "Confirmation d'ajout",
      body: 'Votre signalement a bien été pris en compte ! Merci.',
      buttons: [
        { text: 'Fermer', onClick: () => setModalConfig({ isVisible: false }) },
      ],
    })
  }

  const handleDeleteObservationClick = (
    handleDeleteClick,
    observationId,
    establishmentId,
  ) => {
    handleDeleteClick(observationId, establishmentId)
    setModalConfig({
      isVisible: true,
      title: 'Confirmation de suppression',
      body: 'Le signalement a été supprimé avec succès.',
      buttons: [
        { text: 'Fermer', onClick: () => setModalConfig({ isVisible: false }) },
      ],
    })
  }

  return (
    <ModalContext.Provider
      value={{
        modalConfig,
        setModalConfig,
        handleAddObservationClick,
        handleDeleteObservationClick,
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

export const useModal = () => {
  const context = useContext(ModalContext)
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider')
  }
  return context
}
