import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { useToast } from '../../context/toastContext'
import { useUserContext } from '../../context/userContext'

const BugReportModal = ({ show, onHide }) => {
  const [bugDescription, setBugDescription] = useState('')
  const { addToast } = useToast()
  const { currentUser } = useUserContext() // Récupérer l'utilisateur connecté

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const response = await fetch(
        'https://ecoveille.acclpl.fr/send_email.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            bugDescription,
            userEmail: currentUser?.email || 'Email non disponible', // Utiliser l'email de l'utilisateur connecté
          }),
        },
      )

      const text = await response.text()
      const result = JSON.parse(text)

      if (result.status === 'success') {
        setBugDescription('')
        onHide()
        addToast('Rapport de bug envoyé avec succès', 'success')
      } else {
        addToast(
          "Erreur lors de l'envoi du rapport de bug : " + result.message,
          'danger',
        )
      }
    } catch (error) {
      addToast(
        "Erreur lors de l'envoi du rapport de bug : " + error.message,
        'danger',
      )
    }
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Signaler un bug</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="bugDescription">
            <Form.Label>Description du bug</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={bugDescription}
              onChange={(e) => setBugDescription(e.target.value)}
              required
            />
          </Form.Group>
          <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
              Annuler
            </Button>
            <Button variant="primary" type="submit">
              Soumettre
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default BugReportModal
